import axios from "axios";
const actions = {
  getVideoCategory(context) {
    context.commit("SET_VIDEO_CATEGORY_LIST");
    let getUrl = context.getters.backendServer + "videocategory";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_VIDEO_CATEGORY_LIST", response.data.data);
        return response.data.data;
      })
      .catch(function(e) {
        context.commit("SET_VIDEO_CATEGORY_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteVideo(context, value) {
    let deleteUrl = context.getters.backendServer + "videopost/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .delete(deleteUrl, config)
      .then(function(item) {return item;})
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
