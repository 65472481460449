import mutations from "./mutations";
import actions from "./actions"
import getters from "./getters"

const state = {
  sideMenu: false,
};

const sideMenuModule = {
  state,
  mutations,
  actions,
  getters
};

export default sideMenuModule;
