import axios from "axios";
const actions = {
  getCategoryList(context) {
    context.commit("SET_CATEGORY_LIST");
    let getUrl = context.getters.backendServer + "category";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_CATEGORY_LIST", response.data.data);
        return response.data.data;
      })
      .catch(function(e) {
        context.commit("SET_CATEGORY_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getFileList(context) {
    context.commit("SET_FILE_LIST");
    let getUrl = context.getters.backendServer + "file";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_FILE_LIST", response.data.data);
      })
      .catch(function(e) {
        context.commit("SET_FILE_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  addFile(context, value) {
    context.commit("SET_FILE_ADD");
    let postUrl = context.getters.backendServer + "file";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_FILE_ADD", response);
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich hinzugefügt");
        context.dispatch("getFileList");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_FILE_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  updateFile(context, value) {
    context.commit("SET_FILE_UPDATE");

    let postUrl = context.getters.backendServer + "file/" + value._id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_FILE_UPDATE", response.data);
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich verändert");
        context.dispatch("getFileList");
      })
      .catch(function(e) {
        context.commit("SET_FILE_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleFile(context, value) {
    let postUrl =  context.getters.backendServer + "file?category=" + value.name;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .get(postUrl, config)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        context.dispatch("triggerError", err);
      });
  },

  deleteFile(context, value) {
    context.commit("SET_FILE_DELETE");
    let deleteUrl = context.getters.backendServer + "file/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .delete(deleteUrl, config)
      .then(function() {
        context.commit("SET_FILE_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich gelöscht");
        context.dispatch("getFileList");
      })
      .catch(function(e) {
        context.commit("SET_FILE_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
