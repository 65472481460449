import axios from "axios";
const actions = {
  getBlogList(context, params) {
    context.commit("SET_BLOG_LIST");

    let getUrl =
      context.getters.backendServer +
      "blog?page=" +
      params.pagination.page +
      "&perPage=" +
      params.pagination.perPage +
      "&q=" +
      params.search;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_BLOG_LIST", response.data);
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_BLOG_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleBlog(context, id) {
    let getUrl = context.getters.backendServer + "blog/" + id;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  addBlog(context, value) {
    context.commit("SET_BLOG_ADD");
    let postUrl = context.getters.backendServer + "blog";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_BLOG_ADD", response);
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich hinzugefügt");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_BLOG_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  updateBlog(context, value) {
    context.commit("SET_BLOG_UPDATE");

    let postUrl = context.getters.backendServer + "blog/" + value._id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_BLOG_UPDATE", response.data);
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich verändert");
      })
      .catch(function(e) {
        context.commit("SET_BLOG_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteBlog(context, value) {
    context.commit("SET_BLOG_DELETE");
    let deleteUrl = context.getters.backendServer + "blog/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .delete(deleteUrl, config)
      .then(function() {
        context.commit("SET_BLOG_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich gelöscht");
      })
      .catch(function(e) {
        context.commit("SET_BLOG_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
