<template>
<v-footer padless color="primary">
    <v-row justify="center" no-gutters>
        <v-col class="py-4 text-center white--text" cols="12">
            &copy; {{ new Date().getFullYear() }} — coded by
            <strong>cDevelopment</strong> //
            <strong>{{ companyData.fullName }}</strong>
        </v-col>
    </v-row>
</v-footer>
</template>

<script>
import {
    mapGetters
} from "vuex";
export default {
    data: () => ({}),
    computed: {
        ...mapGetters(["companyData"])
    }
};
</script>
