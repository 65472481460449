import Vue from "vue";
import Router from "vue-router";
import generalLayout from "./components/generalLayout.vue";
import Login from "./views/Login.vue";
import Reset from "./views/Reset.vue";
import Blog from "./views/blog.vue";
import Ads from "./views/ads.vue";
import Download from "./views/download.vue";
import Videos from "./views/videoPost.vue";
import Settings from "./views/informationSettings.vue";
import Users from "./views/settings/users.vue";
import EmailText from "./views/settings/textSettings.vue";
const backendServer = require("./store/config/index").default.state
  .backendServer;
const axios = require("axios");

Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/",
      component: generalLayout,
      name: "generalLayout",
      redirect: "/blog",
      meta: { requiresAuth: true, requireAdmin: false },
      children: [
        {
          path: "/blog",
          name: "Beiträge",
          component: Blog,
          meta: { requiresAuth: true, requireAdmin: false },
        },
        {
          path: "/ads",
          name: "Suche / Biete",
          component: Ads,
          meta: { requiresAuth: true, requireAdmin: false },
        },
        {
          path: "/download/:name",
          name: "Download",
          component: Download,
          meta: { requiresAuth: true, requireAdmin: false },
        },
        {
          path: "/videoPost/:name",
          name: "Videos",
          component: Videos,
          meta: { requiresAuth: true, requireAdmin: false },
        },
        {
          path: "/settings",
          name: "Einstellungen",
          component: Settings,
          meta: { requiresAuth: true, requireAdmin: true },
        },
        {
          path: "/users",
          name: "Benutzerverwaltung",
          component: Users,
          meta: { requiresAuth: true, requireAdmin: true },
        },
        {
          path: "/emailText",
          name: "emailText",
          component: EmailText,
          meta: { requiresAuth: true, requireAdmin: true },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/resetpw/:id",
      name: "Reset",
      props: true,
      component: Reset,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = window.localStorage.getItem("token");
    const role = localStorage.getItem("role");
    if (!token) {
      next("/login");
    } else {
      //CHECK TOKEN
      let getUrl = backendServer + "user/hasCredentials";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      axios
        .get(getUrl, config)
        .then(function(response) {
          if (response.status != 204) {
            next("/login");
          } else {
            if (to.meta.requireAdmin) {
              if (role === "Admin") {
                next();
              } else {
                next("/login");
              }
            } else {
              next();
            }
            next();
          }
        })
        .catch((e) => {
          // when check token fails, remove token ad log in again
          console.error(e);
          localStorage.removeItem("token");
          next("/login");
        });
    }
  } else {
    next();
  }
});

export default router;
