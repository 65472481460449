import axios from "axios";
const actions = {
  getSettingsList(context) {
    context.commit("SET_SETTINGS_LIST");
    let getUrl = context.getters.backendServer + "settings";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_SETTINGS_LIST", response.data);
      })
      .catch(function(e) {
        context.commit("SET_SETTINGS_LIST", e);
        context.dispatch("triggerError", e);
      });
  },
  getSettingParticipationStatus(context) {
    let getUrl = context.getters.backendServer + "settings";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit(
          "SET_SETTINGS_PARTICIPATIONS_STATUS",
          response.data.AllowShareHolding.value
        );
      })
      .catch(function(e) {
        context.commit("SET_SETTINGS_PARTICIPATIONS_STATUS", false);
      });
  },
  setSettingParticipationStatus(context, value) {
    let postUrl =
      context.getters.backendServer + "settings/5e57f46b6c081400115d984e";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    let putValue = {
      name: "AllowShareHolding",
      value: value
    };
    axios
      .put(postUrl, putValue, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Status erfolgreich verändert");
        context.dispatch("getSettingParticipationStatus");
      })
      .catch(function(e) {});
  },
  getEmailText(context) {
    let getUrl = context.getters.backendServer + "meta/emailFooter";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  updateEmailText(context, value) {
    let postUrl = context.getters.backendServer + "meta/emailFooter";

    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("CREATE_SNACKBAR", "Email-Text erfolgreich aktualisiert");
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
