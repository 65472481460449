import axios from "axios";
const actions = {
  getAdList(context, pagination) {
    context.commit("SET_AD_LIST");

    let getUrl =
      context.getters.backendServer +
      "ads?page=" +
      pagination.page +
      "&perPage=" +
      pagination.perPage;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_AD_LIST", response.data);
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_AD_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  getSingleAd(context, id) {
    let getUrl = context.getters.backendServer + "ads/" + id;
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },

  addAd(context, value) {
    context.commit("SET_AD_ADD");
    let postUrl = context.getters.backendServer + "ads";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_AD_ADD", response);
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich hinzugefügt");
        return response.data;
      })
      .catch(function(e) {
        context.commit("SET_AD_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  updateAd(context, value) {
    context.commit("SET_AD_UPDATE");

    let postUrl = context.getters.backendServer + "ads/" + value._id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_AD_UPDATE", response.data);
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich verändert");
      })
      .catch(function(e) {
        context.commit("SET_AD_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteAd(context, value) {
    context.commit("SET_AD_DELETE");
    let deleteUrl = context.getters.backendServer + "ads/" + value.id;

    let config = {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    };
    axios
      .delete(deleteUrl, config)
      .then(function() {
        context.commit("SET_AD_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Beitrag erfolgreich gelöscht");
      })
      .catch(function(e) {
        context.commit("SET_AD_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
