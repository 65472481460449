import { StoreUtil } from "../utils";

const mutations = {

    SET_BLOG_LIST(state, payload) {
        state.blogList = StoreUtil.updateState(state.blogList, payload);
    },
    SET_BLOG_ADD(state, payload) {
        StoreUtil.updateState(state.blogList, payload);
    },
    SET_BLOG_DELETE(state, payload) {
        StoreUtil.updateState(state.blogList, payload);
    },
    SET_BLOG_UPDATE(state, payload) {
        StoreUtil.updateState(state.blogList, payload);
    }
}

export default mutations;