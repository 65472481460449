import { StoreUtil } from "../utils";

const mutations = {

    SET_FILE_LIST(state, payload) {
        state.fileList = StoreUtil.updateState(state.fileList, payload);
    },
    SET_CATEGORY_LIST(state, payload) {
        state.categoryList = StoreUtil.updateState(state.categoryList, payload);
    },
    SET_FILE_ADD(state, payload) {
        StoreUtil.updateState(state.fileList, payload);
    },
    SET_FILE_DELETE(state, payload) {
        StoreUtil.updateState(state.fileList, payload);
    },
    SET_FILE_UPDATE(state, payload) {
        StoreUtil.updateState(state.fileList, payload);
    }
}

export default mutations;