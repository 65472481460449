import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { StoreUtil } from "../utils";

const state = {
  pagination: {
    page: 1,
    itemsPerPage: 5,
    totalItems: 0,
  },
  blogList: StoreUtil.state()
};

const blogModule = {
  state,
  mutations,
  actions,
  getters
};

export default blogModule;
