<template>
  <div>
    <v-toolbar dense class="mb-4">
      <side-menu-icon/>

      <v-toolbar-title>Downloads - {{ $route.params.name }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
          text
          outlined
          color="primary"
          href="https://forms.gle/ynakUmiTGN7jEYDR8"
          target="_blank"
      >
        Inhalte vorschlagen
      </v-btn>
      <v-btn
          v-if="isAdmin"
          text
          color="primary"
          @click="openEditCategoryDialog = true"
      >
        Kategorien bearbeiten
      </v-btn>
      <v-btn
          v-if="isAdmin"
          text
          color="primary"
          @click="openCategoryDialog = true"
      >
        Neue Kategorie anlegen
      </v-btn>
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on }">
          <v-btn icon>
            <v-icon v-on="on" @click="dialog = true">post_add</v-icon>
          </v-btn>
        </template>
        <span>Download hinzufügen</span>
      </v-tooltip>
    </v-toolbar>
    <v-col cols="12" sm="6" md="12">
      <v-card>
        <v-row class="justify-start">
          <v-col
              cols="12"
              sm="6"
              md="4"
              v-for="download in item.content"
              :key="download.id"
          >
            <v-card class="mx-4" max-width="600">
              <v-card-text>
                <div>Download | {{ item.tab }}</div>
                <p class="display-1 text--primary">
                  {{ download.title }}
                </p>
                <p>Bereitgestellt von {{ download.user.name }}</p>
                <div class="text--primary">
                  {{ download.description }}
                </div>
              </v-card-text>
              <div class="pl-2" v-if="userInfo">
                <div v-if="userInfo.role === 'Admin'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon>
                        <v-icon small v-on="on" @click="edit(download)"
                        >edit
                        </v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Download bearbeiten</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon>
                        <v-icon small v-on="on" @click="deleteItem(download)"
                        >delete
                        </v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Download löschen</span>
                  </v-tooltip>
                </div>
              </div>
              <v-card-actions>
                <b font-color="grey" class="pl-2 "
                >Downloads: {{ download.downloadCount }}</b
                >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="downloadFile(download)">
                  Download
                  <v-icon right dark>
                    mdi-cloud-download
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="justify-space-around">
                  <v-col cols="12" sm="6" md="11">
                    <v-text-field
                        label="Titel"
                        v-model="dialogItem.title"
                        dense
                        outlined
                        :rules="requieredRule"
                    ></v-text-field>
                    <v-textarea
                        outlined
                        dense
                        label="Vorschautext / Zusammenfassung des Beitrages"
                        v-model="dialogItem.description"
                        :rules="requieredRule"
                    ></v-textarea>
                    <v-select
                        :items="categoryDataMap"
                        item-text="name"
                        item-value="name"
                        label="Kategorie"
                        v-model="dialogItem.category"
                        :rules="requieredRule"
                        dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="11">
                    <img :src="logoURL" style="max-width: 150px"/>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="11"
                      v-if="dialogTitle != 'Download bearbeiten'"
                  >
                    <v-file-input
                        show-size
                        label="Datei auswählen"
                        v-model="dialogItem.file"
                        accept="*"
                        :rules="requieredRule"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
            >zurück
            </v-btn
            >
            <v-btn color="blue darken-1" text @click="saveMessage"
            >speichern
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="openEditCategoryDialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Kategorien bearbeiten</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-btn
                v-if="isAdmin"
                text
                color="primary"
                @click="openCategoryDialog = true"
            >
              Neue Kategorie anlegen
            </v-btn>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Kategorie
                  </th>
                  <th class="text-left">
                    Erstellt am
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in categoryDataMap" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.formattedCreatedAt }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            medium
                            class="mr-2"
                            v-on="on"
                            @click="removeCategory(item)"
                        >delete
                        </v-icon
                        >
                      </template>
                      <span>Kategorie Löschen</span>
                    </v-tooltip>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="openEditCategoryDialog = false"
          >zurück
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openCategoryDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Neue Kategorie anlegen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-space-around">
              <v-col cols="12" sm="6" md="11">
                <v-text-field
                    label="Name"
                    v-model="categoryDialog.name"
                    dense
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="openCategoryDialog = false"
          >zurück
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              @click="createNewCategory(categoryDialog)"
          >speichern
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueQuillEditor, {Quill} from "vue-quill-editor";
import {ImageDrop} from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import axios from "axios";
import SideMenuIcon from "@/components/sideMenuIcon.vue";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
  components: {SideMenuIcon},
  data: () => ({
    dialog: false,
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    categoryDialog: {
      name: null,
    },
    dialogTitle: "Download erstellen",
    dialogItem: {
      title: "Bezeichnung",
      description: "Kurzbeschreibung der Datei",
      file: null,
      category: null,
    },
    isAdmin: false,
    logoURL: "",
    openCategoryDialog: false,
    openEditCategoryDialog: false,
    dialogRemoveCategory: false,
    tab: null,
    item: {},
    userInfo: null,
    active_tab: 2,
  }),
  computed: {
    ...mapGetters(["fileListGetter", "backendServer", "categoryListGetter"]),
    categoryDataMap() {
      return this.categoryListGetter.data.map((category) => {
        const Description = category.name;
        const id = category.id;
        return Object.assign({}, category, {
          Description,
          id,
        });
      });
    },
    fileDataMap() {
      if (this.fileListGetter.data.length > 0) {
        return this.fileListGetter.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    logo: {
      handler(val) {
        if (val.data[0] != null) {
          this.logoURL = val.data[0].value.dataURL;
        }
      },
    },
    "$route.params.name": function () {
      this.init();
    },
  },
  mounted() {
    this.$store.dispatch("getCategoryList");
    this.$store.dispatch("getUserInfo").then((r) => {
      this.userInfo = r;
      if (r.role == "Admin") {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    });
    this.init();
  },
  methods: {
    init() {
      const name = this.$route.params.name;
      let postUrl = this.backendServer + "file?category=" + name;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      axios
          .get(postUrl, config)
          .then((response) => {
            this.item = {
              tab: name,
              content: response.data.data,
            };
          })
          .catch((err) => {
            this.$store.dispatch("triggerError", err);
          });
    },
    saveMessage() {
      let vm = this;
      if (this.$refs.form.validate()) {
        vm.dialog = false;
        if (vm.dialogTitle == "Download erstellen") {
          if (vm.dialogItem.file != null) {
            vm.dialogItem.isFile = "true";
            let formData = new FormData();
            formData.append("file", vm.dialogItem.file);
            formData.append("title", vm.dialogItem.title);
            formData.append("category", vm.dialogItem.category);
            formData.append("description", vm.dialogItem.description);
            let postUrl = this.backendServer + "file";
            let config = {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            };

            axios
                .post(postUrl, formData, config)
                .then((response) => {
                  this.$store.dispatch(
                      "triggerSnackbar",
                      "Datei erfolgreich hinzugefügt"
                  );
                  this.$store.dispatch("getFileList");
                })
                .catch((err) => {
                  this.$store.dispatch("triggerError", err);
                });
          }
        } else {
          let formData = new FormData();
          if (vm.dialogItem.file != null) {
            vm.dialogItem.isFile = "true";
            formData.append("file", vm.dialogItem.file);
            vm.dialogItem.file = formData;
          } else {
            formData.append("file", vm.dialogItem.file);
            formData.append("title", vm.dialogItem.title);
            formData.append("category", vm.dialogItem.category);
            formData.append("description", vm.dialogItem.description);
          }
          vm.dialogItem.isFile = "false";
          //axios
          let putUrl = this.backendServer + "file/" + vm.dialogItem.id;
          let config = {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          };

          axios
              .put(putUrl, formData, config)
              .then((response) => {
                this.$store.dispatch(
                    "triggerSnackbar",
                    "Datei erfolgreich bearbeitet"
                );
                this.$store.dispatch("getFileList");
              })
              .catch((err) => {
                this.$store.dispatch("triggerError", err);
              });
        }
      }
    },
    edit(item) {
      this.dialogTitle = "Download bearbeiten";
      this.dialog = true;
      this.dialogItem = item;
    },
    deleteItem(item) {
      this.$store.dispatch("deleteFile", item).then((result) => {
        this.$store.dispatch("getFileList");
      });
    },
    handleFileUpload() {
      this.dialogItem.file = this.$refs.file.files[0];
    },
    resetDialogData() {
      this.dialogItem = {
        heading: "Beispielüberschrift",
        html: "<h1>Beispieltext</h1>",
        isFile: "false",
        file: null,
        category: null,
      };
    },
    downloadFile(item) {
      let postUrl = this.backendServer + "file/" + item.filename;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        responseType: "blob",
      };

      axios
          .get(postUrl, config)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", item.title + "." + item.filetype);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$store.dispatch("triggerError", err);
          });
    },
    createNewCategory(categoryDialog) {
      let vm = this;
      let postUrl = this.backendServer + "category/";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
          .post(postUrl, categoryDialog, config)
          .then((response) => {
            vm.$store.dispatch(
                "triggerSnackbar",
                "Kategorie erfolgreich erstellt"
            );
            vm.$store.dispatch("getCategoryList");
            vm.openCategoryDialog = false;
          })
          .catch((err) => {
            this.$store.dispatch("triggerError", err);
            this.openCategoryDialog = false;
          });
    },
    removeCategory(item) {
      let vm = this;
      let postUrl = this.backendServer + "category/" + item.id;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
          .delete(postUrl, config)
          .then((response) => {
            vm.$store.dispatch(
                "triggerSnackbar",
                "Kategorie erfolgreich gelöscht"
            );
            vm.$store.dispatch("getCategoryList");
          })
          .catch((err) => {
            this.$store.dispatch("triggerError", err);
          });
    },
  },
};
</script>

<style>
.ql-editor {
  min-height: 320px !important;
}
</style>
