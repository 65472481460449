<template>
<div>
    <sidemenu v-bind:drawer="drawer"></sidemenu>
    <v-app-bar clipped-right app dark color="primary">
        <v-toolbar-title style="width: 800px">
            <span class="hidden-sm-and-down">{{ companyData.name }} Betriebsvergleich </span>
            <div class="flex-grow-1"></div>
        </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon @click="dialog = true">help</v-icon>
                </v-btn>
            </template>
            <span>Hilfe erhalten</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon @click="logout">exit_to_app</v-icon>
                </v-btn>
            </template>
            <span>Logout</span>
        </v-tooltip>
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    Support erhalten
                </v-card-title>

                <v-card-text>
                    <br />
                    <h4>{{ companyData.name }}</h4>
                    <br />
                    Support für {{ companyData.fullName }} <br />
                    <h3>{{ companyData.supportMail }}</h3>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app-bar>
</div>
</template>

<script>
import sidemenu from "./sideMenu.vue";
import {
    mapGetters
} from "vuex";
export default {
    components: {
        sidemenu
    },
    data: () => ({
        drawer: null,
        dialog: false
    }),
    computed: {
        ...mapGetters(["companyData", "version"])
    },
    methods: {
        logout() {
            localStorage.clear();
            this.$router.push("login");
        }
    }
};
</script>
