import axios from "axios";

const actions = {
  getUserList(context, searchKey) {
    context.commit("SET_USER_LIST");
    let getUrl = null;
    if (searchKey != null && searchKey != "") {
      getUrl = context.getters.backendServer + "user?q=" + searchKey + "&page=1&perPage=10";
    } else {
      getUrl = context.getters.backendServer + "user";
    }
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_USER_LIST", response.data.data);
      })
      .catch(function(e) {
        context.commit("SET_USER_LIST", e);
        context.dispatch("triggerError", e);
      });
  },

  addUser(context, value) {
    context.commit("SET_USER_ADD");
    let postUrl = context.getters.backendServer + "user";
    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };
    return axios
      .post(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_USER_ADD", response.data);
        context.commit("CREATE_SNACKBAR", "Benutzer erfolgreich hinzugefügt");
        context.dispatch("getUserList");
        return true;
      })
      .catch(function(e) {
        context.commit("SET_USER_ADD", e);
        context.dispatch("triggerError", e);
      });
  },

  deleteUser(context, value) {
    context.commit("SET_USER_DELETE");
    let postUrl = context.getters.backendServer + "user/" + value.id;

    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .delete(postUrl, config)
      .then(function() {
        context.commit("SET_USER_DELETE", "[]");
        context.commit("CREATE_SNACKBAR", "Benutzer erfolgreich gelöscht");
        context.dispatch("getUserList");
      })
      .catch(function(e) {
        context.commit("SET_USER_DELETE", e);
        context.dispatch("triggerError", e);
      });
  },

  updateUser(context, value) {
    context.commit("SET_USER_UPDATE");

    let postUrl = context.getters.backendServer + "user/" + value.id;

    let config = {
      headers: {
        authorization: localStorage.getItem("token")
      }
    };

    axios
      .put(postUrl, value, config)
      .then(function(response) {
        context.commit("SET_USER_UPDATE", response.data);
        context.commit(
          "CREATE_SNACKBAR",
          "Nutzer " + response.data.email + " erfolgreich verändert"
        );
        context.dispatch("getUserList");
      })
      .catch(function(e) {
        context.commit("SET_USER_UPDATE", e);
        context.dispatch("triggerError", e);
      });
  },

  getUserCount(context) {
    let getUrl = context.getters.backendServer + "user/getUserCount";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
};

export default actions;
