import Vue from "vue";
import Vuex from "vuex";
import config from "./config/index.js";
import login from "./login/index.js";
import blog from "./blog/index.js";
import ads from "./ads/index.js";
import participations from "./participations/index.js";
import settings from "./settings/index.js";
import user from "./user/index.js";
import file from "./file/index.js"
import video from "./video/index.js"
import sidemenu from "./sidemenu/index.js"

Vue.use(Vuex);
Vue.config.devtools = true;

export default new Vuex.Store({
  modules: {
    config,
    login,
    blog,
    ads,
    participations,
    settings,
    user,
    file,
    video,
    sidemenu,
  }
});
