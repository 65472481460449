import { StoreUtil } from "../utils";

const mutations = {

    SET_AD_LIST(state, payload) {
        state.adList = StoreUtil.updateState(state.adList, payload);
    },
    SET_AD_ADD(state, payload) {
        StoreUtil.updateState(state.adList, payload);
    },
    SET_AD_DELETE(state, payload) {
        StoreUtil.updateState(state.adList, payload);
    },
    SET_AD_UPDATE(state, payload) {
        StoreUtil.updateState(state.adList, payload);
    }
}

export default mutations;