<template>
  <v-data-table
    :headers="headers"
    :items="userList"
    :loading="userstatusPending"
    class="pa-5"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Benutzer Ideenbörse</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          label="Suche..."
          single-line
          hide-details
          dense
          @keyup.enter.native="searchData"
        ></v-text-field>
        <v-btn text icon color="primary" @click="searchData">
          <v-icon>search</v-icon>
        </v-btn>
        <div class="flex-grow-1"></div>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on"
              >Neuer Benutzer</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.prename"
                        label="Vorname"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        v-model="editedItem.surname"
                        label="Nachname"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="8">
                      <v-text-field
                        v-model="editedItem.dorfladenName"
                        label="Bezeichnung/ Name Dorfladen"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.dorfladenStreet"
                        label="Straße"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                      <v-text-field
                        v-model.number="editedItem.dorfladenNumber"
                        type="Number"
                        label="Hs. Nr."
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.dorfladenLocation"
                        label="Ort"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.dorfladenZip"
                        label="Plz"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="8">
                      <v-select
                        :items="roles"
                        item-text="name"
                        item-value="type"
                        label="Nutzertyp"
                        v-model="editedItem.role"
                        :rules="requieredRule"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="requieredRule"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Passwort"
                        hint="Minimum 8 Zeichen"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        dense
                      ></v-text-field>
                      <p v-if="passwordError">
                        Passwörter stimmen nicht überein.
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password1"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="requieredRule"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Passwort wiederholen"
                        hint="Minimum 8 Zeichen"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        dense
                      ></v-text-field>
                      <p v-if="passwordError">
                        Passwörter stimmen nicht überein.
                      </p>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Abbrechen</v-btn>
              <v-btn color="blue darken-1" text @click="save">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEdit" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        :rules="requieredRule"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="8">
                      <v-text-field
                        v-model="editedItem.dorfladenName"
                        label="Bezeichnung/ Name Dorfladen"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.dorfladenStreet"
                        label="Straße"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                      <v-text-field
                        v-model.number="editedItem.dorfladenNumber"
                        type="Number"
                        label="Hs. Nr."
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.dorfladenLocation"
                        label="Ort"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.dorfladenZip"
                        label="Plz"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="8">
                      <v-select
                        :items="roles"
                        item-text="name"
                        item-value="type"
                        label="Nutzertyp"
                        v-model="editedItem.role"
                        :rules="requieredRule"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="close">Abbrechen</v-btn>
              <v-btn color="blue darken-1" text @click="save">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPW" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">neues Passwort vergeben</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="requieredRule"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Passwort"
                        hint="Minimum 8 Zeichen"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        dense
                      ></v-text-field>
                      <p v-if="passwordError">
                        Passwörter stimmen nicht überein.
                      </p>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.password1"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="requieredRule"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Passwort wiederholen"
                        hint="Minimum 8 Zeichen"
                        counter
                        :error="passwordError"
                        @click:append="show1 = !show1"
                        dense
                      ></v-text-field>
                      <p v-if="passwordError">
                        Passwörter stimmen nicht überein.
                      </p>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="blue darken-1" text @click="dialogPW = false"
                >Abbrechen</v-btn
              >
              <v-btn color="blue darken-1" text @click="updatePassword"
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.formattedDorfladenLocation="{ item }">
      {{ item.dorfladenStreet }} {{ item.dorfladenNumber }}
      {{ item.dorfladenZip }} {{ item.dorfladenLocation }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <loading
            :active.sync="userstatusPending"
            :can-cancel="false"
            :is-full-page="true"
          ></loading>
          <v-card-title class="headline">Datensatz löschen?</v-card-title>
          <v-card-text>Soll der Benutzer wirklich gelöscht werden?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteDialog = false"
              >Abbrechen</v-btn
            >
            <v-btn color="primary" text @click="deleteItem()"
              >Jetzt löschen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="d-flex flex-row">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on" @click="editItem(item)"
              >edit</v-icon
            >
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on" @click="changePassword(item)"
              >vpn_key</v-icon
            >
          </template>
          <span>neues Passwort vergeben</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon small v-on="on" @click="openDeleteDialog(item)"
              >delete</v-icon
            >
          </template>
          <span>Löschen</span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:no-data>
      keine Daten vorhanden
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
export default {
  components: {
    Loading,
  },
  data: () => ({
    search: "",
    menu: false,
    snackbar: {
      visible: false,
      text: "Snackbar ...",
    },
    dialog: false,
    dialogPW: false,
    dialogEdit: false,
    show1: false,
    passwordError: false,
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name",
      },
      {
        text: "Bezeichnung/ Name Dorfladen",
        align: "left",
        sortable: false,
        value: "dorfladenName",
      },
      {
        text: "Adresse",
        align: "left",
        sortable: false,
        value: "formattedDorfladenLocation",
      },
      {
        text: "Email",
        align: "left",
        sortable: false,
        value: "email",
      },
      {
        text: "Typ",
        value: "role",
        sortable: true,
      },
      {
        text: "Erstellungsdatum",
        value: "formattedCreatedAt",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        sortable: true,
      },
    ],
    editedIndex: -1,
    editedItem: {
      email: "",
      password: "",
      password1: "",
      role: "",
      dorfladenName: "",
      dorfladenLocation: "",
      dorfladenStreet: "",
      dorfladenNumber: "",
      dorfladenZip: "",
      prename: "",
      surname: "",
    },
    roles: [
      {
        name: "Administrator",
        type: "Admin",
      },
      {
        name: "Benutzer",
        type: "User",
      },
    ],
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    deleteDialog: false,
    toDeleteItem: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neuer Benutzer" : "Benutzer bearbeiten";
    },
    ...mapGetters(["userList", "userstatusPending"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize(searchKey) {
      this.$store.dispatch("getUserList", searchKey);
    },

    editItem(item) {
      this.editedIndex = this.userList.indexOf(item);
      this.editedItem = item;
      this.dialogEdit = true;
    },
    openDeleteDialog(item) {
      this.toDeleteItem = item;
      this.deleteDialog = true;
    },
    changePassword(item) {
      this.editedIndex = this.userList.indexOf(item);
      this.editedItem = item;
      this.editedItem.password = "";
      this.editItem.password1 = "";
      this.dialogPW = true;
    },
    deleteItem() {
      let vm = this;
      this.$store.dispatch("deleteUser", this.toDeleteItem).then((result) => {
        vm.deleteDialog = false;
        vm.toDeleteItem = null;
      });
    },

    close() {
      this.dialog = false;
      this.dialogEdit = false;
      this.dialogPW = false;
      setTimeout(() => {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
      }, 300);
      this.$refs.form.reset();
    },

    updatePassword() {
      let vm = this;
      if (this.editedItem.password === this.editedItem.password1) {
        if (vm.$refs.form.validate()) {
          vm.$store
            .dispatch("updateUser", {
              password: vm.editedItem.password,
              id: vm.editedItem.id,
            })
            .then((result) => {
              vm.close();
            });
        }
      } else {
        this.passwordError = true;
      }
    },

    save() {
      let vm = this;

      if (this.editedIndex > -1) {
        if (vm.$refs.form.validate()) {
          vm.$store
            .dispatch("updateUser", {
              email: vm.editedItem.email.toLowerCase(),
              role: vm.editedItem.role,
              dorfladenName: vm.editedItem.dorfladenName,
              dorfladenLocation: vm.editedItem.dorfladenLocation,
              dorfladenStreet: vm.editedItem.dorfladenStreet,
              dorfladenNumber: vm.editedItem.dorfladenNumber,
              dorfladenZip: vm.editedItem.dorfladenZip,
              id: vm.editedItem.id,
            })
            .then((result) => {
              vm.close();
            });
        }
      } else {
        if (this.editedItem.password === this.editedItem.password1) {
          if (vm.$refs.form.validate()) {
            vm.editedItem.name =
              vm.editedItem.prename + " " + vm.editedItem.surname;
            vm.editedItem.email.toLowerCase();
            vm.$store.dispatch("addUser", vm.editedItem).then((r) => {
              vm.close();
            });
          }
        } else {
          this.passwordError = true;
        }
      }
    },
    searchData() {
      this.initialize(this.search);
    },
  },
};
</script>

<style>
.expanded__content {
  box-shadow: none !important;
  background: white !important;
}
</style>
