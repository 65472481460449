<template>
  <div>
    <v-navigation-drawer
        v-bind:value="$store.getters.isSideMenuExpanded"
        v-on:input="$store.commit('SET_SIDE_MENU_EXPANDED', $event)"
        width="250px"
        app
        v-bind:permanent="!$vuetify.breakpoint.mobile"
    >
      <v-list dense>
        <v-row align="center">
          <v-col cols="12" class="text-center">
            <v-list-item class="px-2">
              <v-img src="../../assets/logo_cropped.png"></v-img>
            </v-list-item>
          </v-col>
        </v-row>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-item v-else :key="item.text" @click="navigateTo(item.route)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- download items mit subitems -->
        <template v-for="item in downloadItems">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color"
                >{{ item.text }} lol</v-list-item-title
              >
            </v-list-item-content>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content active-class="highlighted">
                  <v-list-item-title class="v-list-item-title-color">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              @click="navigateTo(child.route)"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="v-list-item-title-color">{{
                  child.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" @click="navigateTo(item.route)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- video post items mit subitems -->
        <template v-for="item in videoPostItems">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color"
                >{{ item.text }} lol</v-list-item-title
              >
            </v-list-item-content>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.icon"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content active-class="highlighted">
                  <v-list-item-title class="v-list-item-title-color">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              @click="navigateTo(child.route)"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="v-list-item-title-color">{{
                  child.text
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" @click="navigateTo(item.route)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">{{
                item.text
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-group prepend-icon="shopping_basket" append-icon>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content
                @click="openDorfladen()"
                active-class="highlighted"
              >
                <v-list-item-title class="v-list-item-title-color">
                  Dorfladen.app
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item @click="openDorfladen()" active-class="highlighted">
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">
                Webseite
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="openDorfladenAdmin()" active-class="highlighted">
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">
                Adminansicht
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- custom items for admin section-->
        <v-list-group prepend-icon="settings" append-icon v-if="isAdmin">
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content
                @click="navigateTo('Users', 'noTab')"
                active-class="highlighted"
              >
                <v-list-item-title class="v-list-item-title-color">
                  Einstellungen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            @click="navigateTo('Users', 'noTab')"
            active-class="highlighted"
          >
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">
                Benutzer
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="navigateTo('emailText', 'noTab')"
            active-class="highlighted"
          >
            <v-list-item-content>
              <v-list-item-title class="v-list-item-title-color">
                Texteinstellungen
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <div class="text-center">
      <v-snackbar
        v-model="error.visible"
        :timeout="4000"
        left
        color="#ff5252"
        >{{ error.message }}</v-snackbar
      >
      <v-snackbar
        v-model="snackbar.visible"
        :timeout="4000"
        left
        color="secondary"
        >{{ snackbar.message }}</v-snackbar
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["drawer"],
  data: () => ({
    dialog: false,
    items: [
      {
        icon: "send",
        text: "Beiträge",
        route: "blog",
      },
      {
        icon: "search",
        text: "Suche / Biete",
        route: "ads",
      },
    ],
    downloadItems: [
      {
        icon: "cloud_download",
        text: "Downloads",
        route: "download",
        children: [],
      },
    ],
    videoPostItems: [
      {
        icon: "smart_display",
        text: "Videos",
        route: "videoPost",
        children: [],
      },
    ],
    isAdmin: false,
  }),
  computed: {
    ...mapGetters(["error", "snackbar"]),
  },
  mounted() {
    this.initDownlods();
    this.initVideoPosts();
    if (localStorage.getItem("role") == "Admin") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  },
  methods: {
    initDownlods() {
      this.$store.dispatch("getCategoryList").then((r) => {
        r.forEach((element) => {
          this.downloadItems[0].children.push({
            text: element.name,
            route: "download/" + element.name,
          });
        });
      });
    },
    initVideoPosts() {
      this.$store.dispatch("getVideoCategory").then((r) => {
        r.forEach((element) => {
          this.videoPostItems[0].children.push({
            text: element.name,
            route: "videoPost/" + element.id,
          });
        });
      });
    },
    navigateTo(destination) {
      this.$router.replace({ path: "/" + destination });
    },
    openDorfladen() {
      window.open("https://dorfladen.app", "_blank");
    },
    openDorfladenAdmin() {
      window.open("https://admin.dorfladen.app/", "_blank");
    },
  },
};
</script>

<style scoped>
.divider {
  border-top: 1px solid#e8e8e8;
}

.v-list-item-title-color {
  color: #717171;
}
</style>
