<template>
  <div>
    <v-toolbar dense class="mb-4">
      <side-menu-icon/>

      <v-toolbar-title>Videoposts</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
          v-if="isAdmin"
          text
          color="primary"
          @click="openEditCategoryDialog = true"
      >
        bearbeiten
      </v-btn>
      <v-btn
          v-if="isAdmin"
          text
          color="primary"
          @click="openCategoryDialog = true"
      >
        Neue Kategorie anlegen
      </v-btn>
      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on }">
          <v-btn icon>
            <v-icon v-on="on" @click="dialog = true">post_add</v-icon>
          </v-btn>
        </template>
        <span>Videopost hinzufügen</span>
      </v-tooltip>
    </v-toolbar>
    <v-col cols="12" sm="12" md="6" lg="4" xl="3">
      <v-row class="justify-start">
        <v-col
            cols="12"
            sm="12"
            md="12"
            v-for="video in item.content"
            :key="video.id"
        >
          <v-card class="mx-4">
            <v-card-text>
              <p class="display-1 text--primary">
                {{ video.title }}
              </p>
              <!--p>Bereitgestellt von {{ video.user.name }}</p-->
              <div class="d-flex flex-row my-6">
                <video width="100%" controls class="flex-grow-1 rounded">
                  <source :src="video.url" type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </div>
              <div class="text--primary">
                {{ video.description }}
              </div>
            </v-card-text>
            <div class="pl-2" v-if="userInfo">
              <div v-if="userInfo.role === 'Admin'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon>
                      <v-icon small v-on="on" @click="edit(video)"
                      >edit
                      </v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Videopost bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon>
                      <v-icon small v-on="on" @click="deleteItem(video)"
                      >delete
                      </v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Videopost löschen</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
    </v-col>
    </v-row>
    </v-col>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="justify-space-around">
                  <v-col cols="12" sm="6" md="11">
                    <v-text-field
                      label="Titel"
                      v-model="dialogItem.title"
                      dense
                      outlined
                      :rules="requieredRule"
                    ></v-text-field>
                    <v-text-field
                      label="Video Url"
                      v-model="dialogItem.url"
                      dense
                      outlined
                      :rules="requieredRule"
                    ></v-text-field>
                    <v-textarea
                      outlined
                      dense
                      label="Vorschautext / Zusammenfassung des Beitrages"
                      v-model="dialogItem.description"
                      :rules="requieredRule"
                    ></v-textarea>
                    <v-select
                      :items="videocategoryDataMap"
                      item-text="name"
                      item-value="id"
                      label="Kategorie"
                      v-model="dialogItem.category"
                      :rules="requieredRule"
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >zurück</v-btn
            >
            <v-btn color="blue darken-1" text @click="saveMessage"
              >speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="openEditCategoryDialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Kategorien bearbeiten</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-btn
              v-if="isAdmin"
              text
              color="primary"
              @click="openCategoryDialog = true"
            >
              Neue Kategorie anlegen
            </v-btn>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Kategorie
                    </th>
                    <th class="text-left">
                      Erstellt am
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in videocategoryDataMap" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.formattedCreatedAt }}</td>
                    <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            medium
                            class="mr-2"
                            v-on="on"
                            @click="removeCategory(item)"
                            >delete</v-icon
                          >
                        </template>
                        <span>Kategorie Löschen</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="openEditCategoryDialog = false"
            >zurück</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openCategoryDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Neue Kategorie anlegen</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="justify-space-around">
              <v-col cols="12" sm="6" md="11">
                <v-text-field
                  label="Name"
                  v-model="videocategoryDialog.name"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="openCategoryDialog = false"
            >zurück</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="createNewCategory(videocategoryDialog)"
            >speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import SideMenuIcon from "@/components/sideMenuIcon.vue";

export default {
  components: {SideMenuIcon},
  data: () => ({
    dialog: false,
    valid: true,
    requieredRule: [(v) => !!v || "Dieses Feld wird benötigt"],
    videocategoryDialog: {
      name: null,
    },
    dialogTitle: "Videopost erstellen",
    dialogItem: {
      title: "Bezeichnung",
      description: "Kurzbeschreibung des Video",
      url: null,
      category: null,
    },
    isAdmin: false,
    logoURL: "",
    openCategoryDialog: false,
    openEditCategoryDialog: false,
    dialogRemoveCategory: false,
    tab: null,
    item: {},
    userInfo: null,
    active_tab: 2,
  }),
  computed: {
    ...mapGetters([
      "videopostListGetter",
      "backendServer",
      "videoCategoryListGetter",
    ]),
    videocategoryDataMap() {
      return this.videoCategoryListGetter.data.map((videocategory) => {
        const Description = videocategory.name;
        const id = videocategory.id;
        return Object.assign({}, videocategory, {
          Description,
          id,
        });
      });
    },
    videopostDataMap() {
      if (this.videopostListGetter.data.length > 0) {
        return this.videopostListGetter.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    logo: {
      handler(val) {
        if (val.data[0] != null) {
          this.logoURL = val.data[0].value.dataURL;
        }
      },
    },
    "$route.params.name": function() {
      this.init()();
    },
  },
  mounted() {
    this.$store.dispatch("getVideoCategory");
    this.$store.dispatch("getUserInfo").then((r) => {
      this.userInfo = r;
      if (r.role == "Admin") {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    });
    this.init()();
  },
  methods: {
    init() {
      const name = this.$route.params.name;
      let postUrl = this.backendServer + "videopost?category=" + name;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      axios
        .get(postUrl, config)
        .then((response) => {
          this.item = {
            tab: name,
            content: response.data.data,
          };
        })
        .catch((err) => {
          this.$store.dispatch("triggerError", err);
        });
    },
    saveMessage() {
      let vm = this;
      if (this.$refs.form.validate()) {
        vm.dialog = false;
        if (vm.dialogTitle == "Videopost erstellen") {
          console.log("trigger1");
          //create new
          console.log("trigger2");
          let postUrl = this.backendServer + "videopost";
          let config = {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          };
          console.log("trigger3");
          axios
            .post(postUrl, this.dialogItem, config)
            .then((response) => {
              this.$store.dispatch(
                "triggerSnackbar",
                "Video erfolgreich hinzugefügt"
              );
              this.init();
            })
            .catch((err) => {
              this.$store.dispatch("triggerError", err);
            });
        } else {
          //edit
          //axios
          let putUrl = this.backendServer + "videopost/" + vm.dialogItem.id;
          let config = {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          };

          axios
            .put(putUrl, this.dialogItem, config)
            .then((response) => {
              this.$store.dispatch(
                "triggerSnackbar",
                "Video erfolgreich bearbeitet"
              );
              this.init();
            })
            .catch((err) => {
              this.$store.dispatch("triggerError", err);
            });
        }
      }
    },
    edit(item) {
      this.dialogTitle = "Videopost bearbeiten";
      this.dialog = true;
      this.dialogItem = item;
    },
    deleteItem(item) {
      this.$store.dispatch("deleteVideo", item).then((result) => {
        this.init();
      });
    },
    resetDialogData() {
      this.dialogItem = {
        heading: "Beispielüberschrift",
        html: "<h1>Beispieltext</h1>",
        isFile: "false",
        videopost: null,
        videocategory: null,
      };
    },
    videoFile(item) {
      let postUrl = this.backendServer + "videopost/" + item.videopostname;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        responseType: "blob",
      };

      axios
        .get(postUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("video", item.title + "." + item.videoposttype);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$store.dispatch("triggerError", err);
        });
    },
    createNewCategory(videocategoryDialog) {
      let vm = this;
      let postUrl = this.backendServer + "videocategory/";
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .post(postUrl, videocategoryDialog, config)
        .then((response) => {
          vm.$store.dispatch(
            "triggerSnackbar",
            "Kategorie erfolgreich erstellt"
          );
          vm.$store.dispatch("videoCategoryListGetter");
          vm.openCategoryDialog = false;
        })
        .catch((err) => {
          this.$store.dispatch("triggerError", err);
          this.openCategoryDialog = false;
        });
    },
    removeCategory(item) {
      let vm = this;
      let postUrl = this.backendServer + "videocategory/" + item.id;
      let config = {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };

      axios
        .delete(postUrl, config)
        .then((response) => {
          vm.$store.dispatch(
            "triggerSnackbar",
            "Kategorie erfolgreich gelöscht"
          );
          vm.$store.dispatch("videoCategoryListGetter");
        })
        .catch((err) => {
          this.$store.dispatch("triggerError", err);
        });
    },
  },
};
</script>

<style>
.ql-editor {
  min-height: 320px !important;
}
</style>
