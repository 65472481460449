import { StoreUtil } from "../utils";

const mutations = {

    SET_SETTINGS_LIST(state, payload) {
        state.settingsList = StoreUtil.updateState(state.settingsList, payload);
    },
    SET_SETTINGS_PARTICIPATIONS_STATUS(state, payload) {
        state.settingParticipationStatus = payload;
    }
}

export default mutations;