import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sidemenu',{attrs:{"drawer":_vm.drawer}}),_c(VAppBar,{attrs:{"clipped-right":"","app":"","dark":"","color":"primary"}},[_c(VToolbarTitle,{staticStyle:{"width":"800px"}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.companyData.name)+" Betriebsvergleich ")]),_c('div',{staticClass:"flex-grow-1"})]),_c('div',{staticClass:"flex-grow-1"}),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,{on:{"click":function($event){_vm.dialog = true}}},[_vm._v("help")])],1)]}}])},[_c('span',[_vm._v("Hilfe erhalten")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,{on:{"click":_vm.logout}},[_vm._v("exit_to_app")])],1)]}}])},[_c('span',[_vm._v("Logout")])]),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" Support erhalten ")]),_c(VCardText,[_c('br'),_c('h4',[_vm._v(_vm._s(_vm.companyData.name))]),_c('br'),_vm._v(" Support für "+_vm._s(_vm.companyData.fullName)+" "),_c('br'),_c('h3',[_vm._v(_vm._s(_vm.companyData.supportMail))])]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" ok ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }