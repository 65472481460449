<template>
  <div>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Beiträge werden geladen
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="justify-space-around">
      <v-col cols="12" sm="12" md="10" class="pr-0">
        <v-toolbar dense class="mb-4">
          <side-menu-icon />

          <v-toolbar-title>Ideenbörse</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchQuery"
            label="Suche..."
            single-line
            hide-details
            dense
            @keyup.enter.native="searchData"
          ></v-text-field>
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            @click="dialog = true"
            small
            class="ml-4"
          >
            Beitrag erstellen
          </v-btn>
        </v-toolbar>
        <v-card v-for="item in formattedBlogData" :key="item.id" class="mb-6">
          <v-card-title>
            <div class="headline">{{ item.title }}</div>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <br />
            <div v-html="item.content"></div>
            <br />

            <v-container class="pa-1">
              <v-item-group>
                <v-row>
                  <v-col
                    v-for="(item, i) in item.images"
                    :key="i"
                    cols="12"
                    md="6"
                  >
                    <v-item>
                      <v-img
                        :src="item.content"
                        height="400px"
                        class="text-right pa-2"
                        @click="previewImage(item)"
                      >
                        <v-btn icon dark>
                          <v-icon color="primary">
                            visibility
                          </v-icon>
                        </v-btn>
                      </v-img>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-container>
            <v-divider></v-divider>
            <small
              >Beitrag erstellt am {{ item.formattedCreatedAt }} von
              {{ item.user.name }}</small
            >
            <div v-if="userInfo">
              <div v-if="userInfo.role === 'Admin'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon>
                      <v-icon small v-on="on" @click="edit(item)">edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Beitrag bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon>
                      <v-icon small v-on="on" @click="deleteItem(item)"
                        >delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Beitrag löschen</span>
                </v-tooltip>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-pagination
        v-if="!loading"
        v-model="pagination.page"
        :length="pagination.pages"
        :total-visible="7"
        class="mb-4"
        @input="paginationChange"
      ></v-pagination>
    </div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="1000px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="justify-space-around">
                <v-col cols="12" sm="6" md="11">
                  <v-text-field
                    label="Titel"
                    v-model="dialogItem.title"
                    dense
                    outlined
                  ></v-text-field>
                  <!--v-textarea outlined dense label="Vorschautext / Zusammenfassung des Beitrages" v-model="dialogItem.descriptionShort"></v-textarea-->
                </v-col>
                <v-col cols="12" sm="6" md="11">
                  <!--vue-editor v-model="dialogItem.html"></vue-editor-->
                  <quill-editor
                    v-model="dialogItem.content"
                    :options="editorOption"
                  >
                  </quill-editor>
                </v-col>
                <v-col cols="12" sm="6" md="11">
                  <v-file-input
                    show-size
                    label="Bilder anhängen"
                    data-cy="uploadFile"
                    v-model="imageData"
                    @change="processImage"
                    accept="image/*"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="11">
                  <h4 v-if="dialogItem.images.length > 0">Bilder-Vorschau:</h4>
                  <v-container class="pa-1">
                    <v-item-group multiple>
                      <v-row>
                        <v-col
                          v-for="(item, i) in dialogItem.images"
                          :key="i"
                          cols="12"
                          md="6"
                        >
                          <v-img
                            :src="item.content"
                            height="400px"
                            class="text-right pa-2"
                          >
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-item-group>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >zurück
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveMessage"
              >speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="imagePreviewDialog" width="600px">
      <v-card v-if="imagePreviewData">
        <v-card-title>
          <v-img class="white--text align-end" :src="imagePreviewData.content">
            <v-card-title color="primary"
              >{{ imagePreviewData.description }}
            </v-card-title>
          </v-img>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueQuillEditor, { Quill } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
import SideMenuIcon from "@/components/sideMenuIcon.vue";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
export default {
  components: { SideMenuIcon },
  data: () => ({
    searchQuery: "",
    dialog: false,
    dialogTitle: "Beitrag erstellen",
    dialogItem: {
      title: "Beispielüberschrift",
      description: "Kurzbeschreibung des Beitrages",
      content: "<h1>Beispieltext</h1>",
      images: [],
    },
    rulesData: [
      (value) =>
        !value ||
        value.size < 4000000 ||
        "Bitte lade nur Dateien kleiner 4MB hoch!",
    ],
    openPanels: [0, 1, 2],
    formattedBlogData: [],
    userInfo: null,
    imageData: null,
    selectedImage: [],
    imagePreviewDialog: false,
    imagePreviewData: null,
    loading: false,
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote"],

          [
            {
              header: 1,
            },
            {
              header: 2,
            },
          ], // custom button values
          [
            {
              list: "ordered",
            },
            {
              list: "bullet",
            },
          ],
          [
            {
              script: "sub",
            },
            {
              script: "super",
            },
          ], // superscript/subscript
          [
            {
              indent: "-1",
            },
            {
              indent: "+1",
            },
          ], // outdent/indent
          [
            {
              direction: "rtl",
            },
          ], // text direction

          [
            {
              size: [],
            },
          ], // custom dropdown
          [
            {
              header: [1, 2, 3, 4, 5, 6, false],
            },
          ],

          [
            {
              color: [],
            },
            {
              background: [],
            },
          ], // dropdown with defaults from theme
          [
            {
              font: [],
            },
          ],
          [
            {
              align: [],
            },
          ],

          ["clean"],
          ["link"],
        ],
        history: {
          delay: 1000,
          maxStack: 50,
          userOnly: false,
        },
        imageDrop: true,
        imageResize: true,
      },
    },
    pagination: {
      page: 1,
      perPage: 5,
      pages: 1,
    },
  }),
  mounted() {
    //set loader
    this.loading = true;
    this.formatBlogData(this.pagination, this.searchQuery);
    this.$store.dispatch("getUserInfo").then((r) => {
      this.userInfo = r;
    });
  },
  watch: {
    blogListGetter: function(params) {
      if (params != null) {
        this.openPanels = [...Array(this.blogListGetter.data).keys()].map(
          (k, i) => i
        );
      }
    },
  },
  computed: {},
  methods: {
    saveMessage() {
      let vm = this;
      vm.dialog = false;
      if (vm.dialogTitle == "Beitrag erstellen") {
        this.$store.dispatch("addBlog", vm.dialogItem).then((result) => {
          this.formatBlogData(this.pagination, this.searchQuery);
          this.resetDialogData();
        });
      } else {
        vm.dialogItem.isFile = "false";
        this.$store.dispatch("updateBlog", vm.dialogItem).then((result) => {
          this.formatBlogData(this.pagination, this.searchQuery);
          this.resetDialogData();
        });
      }
    },
    edit(item) {
      this.dialogTitle = "Beitrag bearbeiten";
      this.dialog = true;
      this.dialogItem = item;
    },
    deleteItem(item) {
      this.$store.dispatch("deleteBlog", item).then((result) => {});
      //reload
      this.formatBlogData(this.pagination, this.searchQuery);
    },
    handleFileUpload() {
      this.dialogItem.file = this.$refs.file.files[0];
    },
    resetDialogData() {
      this.dialogItem = {
        title: "Beispielüberschrift",
        description: "Kurzbeschreibung des Beitrages",
        content: "<h1>Beispieltext</h1>",
        images: [],
      };
      this.imageData = null;
      this.formatBlogData(this.pagination, this.searchQuery);
    },
    formatBlogData(pagination, search) {
      //set loader
      this.loading = true;
      this.$store
        .dispatch("getBlogList", { pagination: pagination, search: search })
        .then((r) => {
          this.pagination.pages = Math.floor(r.count / pagination.perPage + 1);
          this.formattedBlogData = r.data;
          this.loading = false;
        });
    },
    processImage() {
      this.getBase64(this.imageData).then((data) => {
        if (this.dialogItem.images.length > 3) {
          this.$store.dispatch(
            "triggerSnackbar",
            "Achtung max. 4 Bilder möglich"
          );
        } else {
          this.dialogItem.images.push(data);
        }
        this.imageData = null;
      });
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve({ content: reader.result, description: file.name });
        reader.onerror = (error) => reject(error);
      });
    },
    previewImage(item) {
      this.imagePreviewDialog = true;
      this.imagePreviewData = item;
    },
    paginationChange(val) {
      //set page
      this.pagination.page = val;
      this.formatBlogData(this.pagination, this.searchQuery);
    },
    searchData() {
      console.warn(this.searchQuery);
      this.formatBlogData(this.pagination, this.searchQuery);
    },
  },
};
</script>

<style>
.ql-editor {
  min-height: 320px !important;
}
</style>
