<template>
  <v-app id="inspire">
    <v-main>
      <div class="text-center">
        <v-snackbar
          v-model="error.visible"
          :timeout="4000"
          left
          color="#ff5252"
          >{{ error.message }}</v-snackbar
        >
        <v-snackbar
          v-model="snackbar.visible"
          :timeout="4000"
          left
          color="secondary"
          >{{ snackbar.message }}</v-snackbar
        >
      </div>
      <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
      ></loading>
      <v-container class="background fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="6" sm="6" md="3">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Neues Kennwort vergeben</v-toolbar-title>
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    id="password"
                    label="Kennwort"
                    name="password1"
                    prepend-icon="lock"
                    v-model="login.password1"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    dense
                    outlined
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Kennwort bestätigen"
                    name="password2"
                    prepend-icon="lock"
                    v-model="login.password2"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    dense
                    outlined
                  ></v-text-field>
                </v-form>
                <b v-if="errorNote"
                  >Beide Kennwörter müssen vorhanden und identisch sein.</b
                >
              </v-card-text>
              <v-card-actions class="pt-0">
                <div class="flex-grow-1"></div>
                <v-btn @click="resetPW" color="primary">Kennwort ändern</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <appFooter />
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import "vue-loading-overlay/dist/vue-loading.css";
import appFooter from "../components/styling/footer";
import Vuex from "vuex";
export default {
  data: () => ({
    drawer: null,
    login: {
      password1: "",
      password2: ""
    },
    show: false,
    errorNote: false,
    id: null
  }),
  components: {
    Loading,
    appFooter
  },
  computed: {
    ...mapGetters(["loading", "loggedIn", "error", "snackbar", "companyData"])
  },
  mounted() {
    if (!this.$route.params.id) {
      return;
    } else {
        this.id = this.$route.params.id;
    }
  },
  methods: {
    resetPW() {
      let vm = this;
      this.errorNote = false;
      if (
        this.login.password1 === this.login.password2 &&
        this.login.password1 != ""
      ) {
        this.$store
          .dispatch("resetNewPassword", {
            id: this.id,
            password: this.login.password1
          })
          .then(() => {
            this.$router.replace({ path: "/login" });
          });
      } else {
        this.errorNote = true;
      }
    }
  }
};
</script>

<style>
.background {
  background-color: #f3f3fa;
}
</style>
