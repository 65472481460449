import axios from "axios";

const actions = {
  login({ commit, getters, dispatch }, loginData) {
    commit("LOGIN_PENDING");
    return axios
      .post(getters.backendServer + "login", {
        email: loginData.email,
        password: loginData.password
      })
      .then(response => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("role", response.data.role);
        commit("SET_TOKEN", response.data.token);
        commit("SET_USER_ROLE", response.data.role);
        commit("LOGIN_SUCCESS");
        dispatch("getUserInfo");
      })
      .catch(err => {
        dispatch("triggerError", err);
        commit("CREATE_SNACKBAR", "Passwort oder email nicht korrekt.");
        commit("LOGIN_FAILED");
      });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      localStorage.removeItem("token");
      commit("SET_TOKEN", null);
      resolve();
    });
  },
  resetPassword(context, item) {
    let postUrl = context.getters.backendServer + "user/password/reset";
    return axios
      .post(postUrl, item)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  resetNewPassword(context, item) {
    let putUrl = context.getters.backendServer + "user/password/reset/" + item.id;
    return axios
      .put(putUrl, item)
      .then(function(response) {
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  },
  getUserInfo(context) {
    let getUrl = context.getters.backendServer + "user/info";
    let config = {
      headers: {
        Authorization: localStorage.getItem("token")
      }
    };

    return axios
      .get(getUrl, config)
      .then(function(response) {
        context.commit("SET_USER_INFO", response.data);
        return response.data;
      })
      .catch(function(e) {
        context.dispatch("triggerError", e);
      });
  }
};

export default actions;
