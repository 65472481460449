import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { StoreUtil } from "../utils";

const state = {
  pagination: {
    page: 1,
    itemsPerPage: 5,
    totalItems: 0,
  },
  adList: StoreUtil.state()
};

const adModule = {
  state,
  mutations,
  actions,
  getters
};

export default adModule;
