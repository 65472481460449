<template>
  <v-app id="inspire">
    <v-main>
      <div class="text-center">
        <v-snackbar
          v-model="error.visible"
          :timeout="4000"
          left
          color="#ff5252"
          >{{ error.message }}</v-snackbar
        >
        <v-snackbar
          v-model="snackbar.visible"
          :timeout="4000"
          left
          color="secondary"
          >{{ snackbar.message }}</v-snackbar
        >
      </div>
      <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
      ></loading>
      <v-container class="background fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="6" sm="6" md="3">
            <v-card class="elevation-12" v-if="!passwordReset">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title
                  >{{ companyData.name }} Betriebsvergleich</v-toolbar-title
                >
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Email"
                    name="login"
                    prepend-icon="person"
                    type="text"
                    v-model="login.email"
                    dense
                    outlined
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    v-model="login.password"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    dense
                    outlined
                  ></v-text-field>
                </v-form>
                <a href="#" @click="openResetPassword(login.email)"
                  >Kennwort zurücksetzen</a
                >
              </v-card-text>
              <v-card-actions class="pt-0">
                <div class="flex-grow-1"></div>
                <v-btn v-on:click="auth" color="primary">Login</v-btn>
              </v-card-actions>
            </v-card>
            <!-- else: -->
            <v-card class="elevation-12" v-if="passwordReset">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Kennwort zurücksetzen</v-toolbar-title>
                <div class="flex-grow-1"></div>
              </v-toolbar>
              <v-card-text>
                <v-form v-if="!passwordResetSend">
                  <v-text-field
                    label="Email"
                    name="login"
                    prepend-icon="person"
                    type="text"
                    v-model="login.email"
                    dense
                    outlined
                  ></v-text-field>
                </v-form>
                <!-- else: -->
                <v-form v-if="passwordResetSend">
                  <b
                    >Sofern diese Email in unserem System existiert wurde eine
                    Email mit weiteren Anweisung versendet.</b
                  >
                </v-form>
              </v-card-text>
              <v-card-actions class="pt-0">
                <div class="flex-grow-1"></div>
                <v-btn v-if="!passwordResetSend" @click="resetPassword(login.email)" color="primary"
                  >Absenden</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <appFooter />
  </v-app>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
import "vue-loading-overlay/dist/vue-loading.css";
import appFooter from "../components/styling/footer";
import Vuex from "vuex";
export default {
  data: () => ({
    drawer: null,
    login: {
      email: "",
      password: ""
    },
    show: false,
    passwordReset: false,
    passwordResetSend: false
  }),
  components: {
    Loading,
    appFooter
  },
  computed: {
    ...mapGetters(["loading", "loggedIn", "error", "snackbar", "companyData"])
  },
  methods: {
    auth() {
      let vm = this;
      this.$store
        .dispatch("login", {
          email: this.login.email.toLowerCase(),
          password: this.login.password
        })
        .then(() =>
          this.loggedIn === true
            ? vm.$router.push("blog")
            : vm.$router.push("login")
        );
    },
    openResetPassword(email) {
      this.passwordReset = true;
      this.passwordResetSend = false;
    },
    resetPassword(email) {
      if (email) {
        this.passwordResetSend = true;
        this.$store
          .dispatch("resetPassword", {
            email: email
          })
      }
    }
  }
};
</script>

<style>
.background {
  background-color: #f3f3fa;
}
</style>
